export class BaseBackend {
  async fetchRoot() {}
  async fetchHabit(habitUuid) {}
  async fetchProject(projectUuid) {}
  async fetchTask(taskUuid) {}

  async createHabit(habit) {}
  async deleteHabit(habitUuid) {}
  async setHabitName(habitUuid, name) {}
  async setHabitIcon(habitUuid, icon) {}
  async getHabitLogFor7Days(habitUuid) {}
  async markHabitDoneForToday(habitUuid) {}
  async unmarkHabitDoneForToday(habitUuid) {}
  async moveHabitToIndex(habitUuid, index) {}

  async createProject(project) {}
  async deleteProject(projectUuid) {}
  async moveProjectToTop(projectUuid) {}
  async moveProjectAfter(projectUuid, afterProjectUuid) {}
  async setProjectName(projectUuid, name) {}
  async setProjectCollapsed(projectUuid, collapsed) {}

  async createTask(task) {}
  async deleteTask(taskUuid) {}
  async setTaskName(taskUuid, name) {}
  async moveTaskToTop(taskUuid, projectUuid, status) {}
  async moveTaskAfter(taskUuid, afterTaskUuid) {}
}
