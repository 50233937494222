import { EditingEverythingContext, TaskDraggerContext } from "./App";
import { deleteHabit, findHabit, setHabitAnti, setHabitEmoji } from "./database";
import { useContext, useRef, useState } from "react";

import EmojiPicker from "emoji-picker-react";
import { Habit } from "./Habit";
import { Magnet } from "./Magnet";
import { NewHabitButton } from "./NewHabitButton";

export function Habits({ habits }) {
  const { editingEverything, setEditingEverything } = useContext(
    EditingEverythingContext
  );
  const { maybeExitEditingEverything } = useContext(TaskDraggerContext);
  const { ghostTarget, globalDragging, ignoreTarget } =
    useContext(TaskDraggerContext);

  const editingHabitNum = editingEverything ? 1 : 0;
  const height = Math.ceil(Math.max(habits.length + editingHabitNum, 1) / 3);

  const targetTokens = (ghostTarget || "").split(" ");
  const ignoreTargetTokens = (ignoreTarget || "").split(" ");
  const isHabitDragging = targetTokens[0] === "habit";
  const idxGap =
    isHabitDragging && globalDragging ? parseInt(targetTokens[2]) : 10000;
  const idxCurrent =
    isHabitDragging && globalDragging ? parseInt(ignoreTargetTokens[2]) : 10000;

  const [editingEmoji, setEditingEmoji] = useState(false);
  const uuidOfEmojiEditing = useRef();

  return (
    <>
      <div className="habits section">
        <div
          style={{
            position: "relative",
            height: height * 120,
            width: 3 * 100,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: -20,
              zIndex: 1000,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {editingEmoji && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                    backdropFilter: "blur(3px)",
                  }}
                  onClick={(e) => {
                    setEditingEmoji(false);
                    e.stopPropagation();
                  }}
                ></div>
                <div onClick={(e) => e.stopPropagation()}>
                  <EmojiPicker
                    emojiStyle="native"
                    previewConfig={{
                      defaultCaption: "Pick an emoji for the habit!",
                    }}
                    onEmojiClick={(e) => {
                      maybeExitEditingEverything.current = false;
                      setHabitEmoji(uuidOfEmojiEditing.current, e.emoji);
                      setEditingEmoji(false);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {habits.map((habitUuid, idx) => {
            idx = idx > idxCurrent ? idx - 1 : idx;
            idx = idx >= idxGap ? idx + 1 : idx;
            return (
              <div
                style={{
                  position: "absolute",
                  top: Math.floor(idx / 3) * 130,
                  left: (idx % 3) * 100,
                  transition: globalDragging ? "top 0.2s, left 0.2s" : "",
                }}
                key={habitUuid}
              >
                <Habit
                  habitUuid={habitUuid}
                  dragIdx={idx}
                  editEmoji={() => {
                    setEditingEmoji(true);
                    uuidOfEmojiEditing.current = habitUuid;
                  }}
                />
                {editingEverything && (
                  <>
                    <div
                      className={[
                        "habit-delete",
                        globalDragging && editingEverything ? "hidden" : "",
                      ].join(" ")}
                      onClick={(e) => {
                        deleteHabit(habitUuid);
                        e.stopPropagation();
                      }}
                    >
                      ❌
                    </div>
                    <div
                      className={[
                        "habit-icon-btn habit-icon-yinyang",
                        globalDragging && editingEverything ? "hidden" : "",
                      ].join(" ")}
                      onClick={(e) => {
                        setHabitAnti(
                          habitUuid,
                          !findHabit(habitUuid).antihabit
                        );
                        // setEditingEmoji(true);
                        // uuidOfEmojiEditing.current = habitUuid;
                        e.stopPropagation();
                      }}
                    ></div>
                  </>
                )}
              </div>
            );
          })}
          {!globalDragging && (editingEverything || habits.length === 0) && (
            <div
              style={{
                position: "absolute",
                top: Math.floor(habits.length / 3) * 130,
                left: (habits.length % 3) * 100,
              }}
            >
              <NewHabitButton />
            </div>
          )}
          {habits.map((habitUuid, idx) => (
            <div
              style={{
                position: "absolute",
                top: Math.floor(idx / 3) * 150 + 75,
                left: (idx % 3) * 150 + 75,
              }}
              key={habitUuid}
            >
              <Magnet
                magnetType={"habit"}
                targetName={"habit idx " + idx}
                style={{
                  height: 0,
                }}
                // ref={magnetRef}
                // noSpace={isDragging}
                // horizontal={horizontal}
                centerSnap={true}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
