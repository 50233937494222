import "./App.css";

import {
  RootType,
  fetchProjects,
  lookupTask,
  moveHabitToIndex,
  moveProjectAfter,
  moveProjectToTop,
  moveTaskAfter,
  moveTaskToTop,
} from "./database";
import {
  cancelSound,
  doneSound,
  editEverythingSound,
  enterSound,
  moveDoneSound,
  moveSound,
  trashSound,
} from "./sounds";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Agreement } from "./Agreement";
import { Habits } from "./Habits";
import { Projects } from "./Projects";
import _ from "lodash";
import { useBlockParty } from "./BlockParty";

export const LoadingContext = createContext();
export const TaskDraggerContext = createContext();
export const EditingEverythingContext = createContext();
export const AutoEditContext = createContext();

function App() {
  const [isAgreed, setIsAgreed] = useState(localStorage.getItem("isAgreed"));

  return (
    <>
      {isAgreed ? (
        <Internal
          setDisagree={(agree) => {
            setIsAgreed(agree);
            localStorage.setItem("isAgreed", agree);
          }}
        />
      ) : (
        <Agreement
          setIsAgreed={(agree) => {
            setIsAgreed(agree);
            localStorage.setItem("isAgreed", agree);
          }}
        />
      )}
    </>
  );
}

function Internal({ setDisagree }) {
  const [isLoading, setIsLoading] = useState(true);
  useMemo(async () => {
    setIsLoading(true);
    await fetchProjects();
    setIsLoading(false);
  }, []);

  const [root] = useBlockParty("root", RootType, { projects: [], habits: [] });
  const [editingEverything, setEditingEverything] = useState(false);
  useEffect(() => {
    if (editingEverything) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [editingEverything]);
  const [autoEdit, setAutoEdit] = useState(null);

  const maybeExitEditingEverything = useRef(null);

  const ghostRef = useRef({});
  const [initialBoundingRect, setInitialBoundingRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    mx: 0,
    my: 0,
  });
  const [currentTask, setCurrentTask] = useState(null);
  const [ghostTarget, setGhostTarget] = useState(null);
  const [globalDragging, setGlobalDragging] = useState(false);
  const [ignoreTarget, setIgnoreTarget] = useState(null);
  const [magnetType, setMagnetType] = useState(null);

  const magnetRef = useRef(null);
  const targetPosition = useRef({ x: 0, y: 0 });
  const currentGhostPosition = useRef({ x: 0, y: 0 });

  const findMagnets = useMemo(
    () =>
      _.throttle(() => {
        const magnets = [
          ...document.querySelectorAll(
            `.task-magnet.lookup-${magnetType}-magnet`
          ),
        ];
        const magnetsWithBoundingRect = magnets.map((magnet) => [
          magnet,
          magnet.getBoundingClientRect(),
        ]);
        return magnetsWithBoundingRect;
      }, 1),
    [magnetType]
  );

  const onMouseMove = useCallback(
    (e) => {
      const newMousePosition = {
        x:
          e.clientX ||
          e.pageX ||
          (e.touches && e.touches[0]?.pageX) ||
          (e.touches && e.touches[0]?.clientX),
        y:
          e.clientY ||
          e.pageY ||
          (e.touches && e.touches[0]?.pageY) ||
          (e.touches && e.touches[0]?.clientY),
      };

      if (globalDragging) {
        const deltaMx =
          newMousePosition.x + window.scrollX - initialBoundingRect.mx;
        const deltaMy =
          newMousePosition.y + window.scrollY - initialBoundingRect.my;
        const actualX = initialBoundingRect.x + deltaMx;
        const actualY = initialBoundingRect.y + deltaMy;

        let targetX = actualX;
        let targetY = actualY;

        targetPosition.current.x = targetX;
        targetPosition.current.y = targetY;

        const magnetsWithBoundingRect = findMagnets();
        let closest = null;
        let closestDistance = Infinity;
        let closestPosition = { x: 0, y: 0 };
        magnetsWithBoundingRect.forEach(([magnet, boundingRect]) => {
          if (magnet.attributes.target.value === ignoreTarget) {
            return;
          }
          const ghostCenter = {
            x: newMousePosition.x + window.scrollX,
            y: newMousePosition.y + window.scrollY,
            // x: actualX + initialBoundingRect.width / 2,
            // y: actualY + initialBoundingRect.height / 2,
          };
          const rect = boundingRect;
          const rectCenter = {
            x: rect.x + window.scrollX,
            y: rect.y + window.scrollY,
          };

          // if (magnet.classList.contains("task-magnet-vertical")) {
          rectCenter.x += rect.width / 2;
          // }

          if (magnet.classList.contains("task-magnet-block")) {
            rectCenter.y += rect.height / 2;
          }

          const distance = Math.sqrt(
            Math.pow(ghostCenter.x - rectCenter.x, 2) +
              Math.pow(ghostCenter.y - rectCenter.y, 2)
          );
          if (distance < closestDistance) {
            closestDistance = distance;
            closest = magnet;
            closestPosition.x = rect.x + window.scrollX;
            closestPosition.y = rect.y + window.scrollY;
          }
        });
        if (closestDistance < 100 || magnetType === "project") {
          setGhostTarget(closest.attributes.target.value);
          magnetRef.current = closest;
        } else {
          currentGhostPosition.current.x = targetX;
          currentGhostPosition.current.y = targetY;
          setGhostTarget(null);
          ghostRef.current.style.transition = null;
          magnetRef.current = null;
        }
        return false;
      }
    },
    [globalDragging, initialBoundingRect, ignoreTarget, findMagnets]
  );

  useEffect(() => {
    if (globalDragging) {
      ghostRef.current.style.top = initialBoundingRect.y + "px";
      ghostRef.current.style.left = initialBoundingRect.x + "px";
      ghostRef.current.style.width = initialBoundingRect.width + "px";
      ghostRef.current.style.height = initialBoundingRect.height + "px";

      currentGhostPosition.current.x = initialBoundingRect.x;
      currentGhostPosition.current.y = initialBoundingRect.y;

      magnetRef.current = null;
    }
    document.body.addEventListener("mousemove", onMouseMove);
    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseMove, globalDragging, initialBoundingRect]);

  const stopDragging = () => {
    ghostRef.current.style.left = 0 + "px";
    ghostRef.current.style.top = 0 + "px";

    if (!ghostTarget) {
      cancelSound.play();
      return;
    }

    const tokens = ghostTarget.split(" ");
    if (magnetType === "task") {
      let taskStatus;
      if (tokens.length === 3) {
        const targetProject = tokens[0];
        taskStatus = tokens[1];
        const targetPosition = tokens[2];
        if (targetPosition === "top") {
          if (!moveTaskToTop(currentTask, targetProject, taskStatus)) {
            taskStatus = "noop";
          }
        }
      } else if (tokens.length === 2) {
        const targetTask = tokens[0];
        const taskPosition = tokens[1];
        const task = lookupTask(targetTask);
        taskStatus = task.status;
        if (taskPosition === "after") {
          if (!moveTaskAfter(currentTask, targetTask)) {
            taskStatus = "noop";
          }
        }
      }

      if (taskStatus === "done") {
        doneSound.play();
      } else if (taskStatus === "trash") {
        trashSound.play();
      } else if (taskStatus === "noop") {
        // :)
      } else {
        moveSound.play();
      }
    } else if (magnetType === "project") {
      if (tokens[0] === "root" && tokens[1] === "top") {
        moveProjectToTop(currentTask);
      } else if (tokens[1] === "after") {
        moveProjectAfter(currentTask, tokens[0]);
      }
      moveSound.play();
    } else if (magnetType === "habit") {
      const position = tokens[2];
      moveHabitToIndex(currentTask, position);
      moveSound.play();
    }
  };

  useEffect(() => {
    let animationFrame;
    let lastTime = Date.now();
    const onFrame = () => {
      const deltaTime = Date.now() - lastTime;
      lastTime = Date.now();

      if (globalDragging) {
        ghostRef.current.style.left = currentGhostPosition.current.x + "px";
        ghostRef.current.style.top = currentGhostPosition.current.y + "px";

        if (magnetRef.current) {
          const rect = magnetRef.current.getBoundingClientRect();
          let goalX = rect.x + window.scrollX;
          let goalY = rect.y + window.scrollY;

          if (magnetRef.current.classList.contains("task-magnet-center-snap")) {
            goalX = goalX - initialBoundingRect.width / 2;
            goalY = goalY - initialBoundingRect.height / 2;
          }

          // For some UX, lets have the target position influence the goal a tiny bit.
          goalX = goalX + (targetPosition.current.x - goalX) * 0.4;
          goalY = goalY + (targetPosition.current.y - goalY) * 0.4;

          currentGhostPosition.current.x =
            currentGhostPosition.current.x +
            (goalX - currentGhostPosition.current.x) *
              Math.min(deltaTime / 10, 1);
          currentGhostPosition.current.y =
            currentGhostPosition.current.y +
            (goalY - currentGhostPosition.current.y) *
              Math.min(deltaTime / 10, 1);

          if (magnetRef.current.classList.contains("task-magnet-block")) {
            ghostRef.current.style.transform = `scale(0.7)`;
          } else {
            ghostRef.current.style.transform = `scale(1)`;
          }
        } else {
          ghostRef.current.style.transform = `scale(1)`;
        }
      }

      animationFrame = requestAnimationFrame(onFrame);
    };
    animationFrame = requestAnimationFrame(onFrame);
    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [globalDragging]);

  // This allows for exiting the editing state when clicking outside of the projects.
  useEffect(() => {
    if (editingEverything) {
      editEverythingSound.play();
      const onMouseDown = (e) => {
        maybeExitEditingEverything.current = true;
      };
      document.addEventListener("pointerdown", onMouseDown);
      const onClick = (e) => {
        if (maybeExitEditingEverything.current) {
          setEditingEverything(false);
        }
        maybeExitEditingEverything.current = false;
      };
      document.addEventListener("click", onClick);
      return () => {
        document.removeEventListener("click", onClick);
        document.removeEventListener("pointerdown", onMouseDown);
      };
    } else {
      maybeExitEditingEverything.current = false;
    }
  }, [editingEverything]);

  return (
    <div className={["app", editingEverything && "app-editing"].join(" ")}>
      <div ref={ghostRef} className="ghost"></div>
      <LoadingContext.Provider value={{ isLoading }}>
        <TaskDraggerContext.Provider
          value={{
            ghostRef,
            initialBoundingRect,
            setInitialBoundingRect,
            ghostTarget,
            setGhostTarget,
            globalDragging,
            setGlobalDragging,
            ignoreTarget,
            setIgnoreTarget,
            stopDragging,
            onMouseMove,
            setCurrentTask,
            setMagnetType,
            maybeExitEditingEverything,
          }}
        >
          <AutoEditContext.Provider value={{ autoEdit, setAutoEdit }}>
            <EditingEverythingContext.Provider
              value={{ editingEverything, setEditingEverything }}
            >
              <Habits habits={root.habits} />
              <Projects projects={root.projects}></Projects>
              {editingEverything && (
                <div className="editing-menu">
                  <div className="editing-vanity">Done</div>
                </div>
              )}
              <div className="footer">
                developed and used by{" "}
                <a
                  href="https://sochinstudio.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  sochin studio llc
                </a>{" "}
                (c) 2024.{" "}
                <a href="#" onClick={() => setDisagree(false)}>
                  view agreement.
                </a>{" "}
                join our <a href="https://discord.gg/9ndxEk2hte">discord</a> for
                help or bug reports.
              </div>
            </EditingEverythingContext.Provider>
          </AutoEditContext.Provider>
        </TaskDraggerContext.Provider>
      </LoadingContext.Provider>
    </div>
  );
}

export default App;
