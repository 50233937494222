import { moveDoneSound, moveSound } from "./sounds";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { Magnet } from "./Magnet";
import { TaskDraggerContext } from "./App";
import { createPortal } from "react-dom";

export function Draggable({
  children,
  targetName,
  disable,
  onNoDragClick,
  uuid,
  magnetType,
  horizontal,
  magnetless,
  overrideTouchAction,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const taskRef = useRef();
  const magnetRef = useRef();

  const {
    ghostRef,
    initialBoundingRect,
    setInitialBoundingRect,
    setGlobalDragging,
    setGhostTarget,
    setIgnoreTarget,
    stopDragging,
    onMouseMove,
    setCurrentTask,
    setMagnetType,
  } = useContext(TaskDraggerContext);

  const onStartDrag = useCallback(
    (e) => {
      if (disable) {
        return;
      }

      // e.preventDefault();

      const newMousePosition = {
        x:
          e.clientX ||
          e.pageX ||
          (e.touches && e.touches[0]?.pageX) ||
          (e.touches && e.touches[0]?.clientX),
        y:
          e.clientY ||
          e.pageY ||
          (e.touches && e.touches[0]?.pageY) ||
          (e.touches && e.touches[0]?.clientY),
      };

      setIsDragging(true);
      setGlobalDragging(true);
      setGhostTarget(targetName);
      setIgnoreTarget(targetName);
      setCurrentTask(uuid);
      setMagnetType(magnetType);
      moveDoneSound.play();

      const boundingRect = taskRef.current.getBoundingClientRect();
      setInitialBoundingRect({
        x: boundingRect.x + window.scrollX,
        y: boundingRect.y + window.scrollY,
        width: boundingRect.width,
        height: boundingRect.height,
        mx: newMousePosition.x + window.scrollX,
        my: newMousePosition.y + window.scrollY,
      });

      if (!magnetless) {
        magnetRef.current.style.transition = "none";
        magnetRef.current.style.height = boundingRect.height;
        setTimeout(() => {
          magnetRef.current.style.transition = null;
        }, 0);
      }
    },
    [
      setInitialBoundingRect,
      setGlobalDragging,
      setGhostTarget,
      setIgnoreTarget,
      targetName,
      setCurrentTask,
      uuid,
      disable,
      magnetless,
      magnetType,
      setMagnetType,
    ]
  );

  const onStopDrag = useCallback(
    (e) => {
      document.body.style.overflow = "auto";
      if (isDragging) {
        const newMousePosition = {
          x:
            e.clientX ||
            e.pageX ||
            (e.touches && e.touches[0]?.pageX) ||
            (e.touches && e.touches[0]?.clientX),
          y:
            e.clientY ||
            e.pageY ||
            (e.touches && e.touches[0]?.pageY) ||
            (e.touches && e.touches[0]?.clientY),
        };

        const distanceBetweenMouseAndInitialMouse = Math.sqrt(
          Math.pow(
            newMousePosition.x + window.scrollX - initialBoundingRect.mx,
            2
          ) +
            Math.pow(
              newMousePosition.y + window.scrollY - initialBoundingRect.my,
              2
            )
        );

        if (distanceBetweenMouseAndInitialMouse < 10) {
          //   setTaskText(task.name);
          //   setIsEditing(true);

          onNoDragClick();
        }

        // e.preventDefault();
        setIsDragging(false);
        setGlobalDragging(false);

        stopDragging();
      }
    },
    [
      isDragging,
      stopDragging,
      initialBoundingRect,
      setGlobalDragging,
      onNoDragClick,
    ]
  );

  const onStopDragRef = useRef();
  onStopDragRef.current = onStopDrag;

  const onMouseMoveRef = useRef();
  onMouseMoveRef.current = onMouseMove;

  const onStartDragRef = useRef();
  onStartDragRef.current = onStartDrag;

  useEffect(() => {
    const taskEle = taskRef.current;
    if (!taskEle) return;
    const onStartDrag = (e) => {
      return onStartDragRef.current(e);
    };
    const onTouchMove = (e) => {
      onMouseMoveRef.current(e);
      return false;
    };
    const onStopDrag = (e) => {
      onStopDragRef.current(e);
    };
    const onTouchCancel = (e) => {
      e.preventDefault();
    };
    // taskEle.addEventListener("touchstart", onStartDrag, {
    //   passive: false,
    // });
    // taskEle.addEventListener("pointerup", onStopDrag, {
    //   passive: false,
    // });
    // taskEle.addEventListener("touchcancel", onTouchCancel, {
    //   passive: false,
    // });
    document.body.addEventListener("pointermove", onTouchMove, {
      passive: false,
    });
    document.body.addEventListener("pointerup", onStopDrag, {
      passive: false,
    });
    return () => {
      // taskEle.removeEventListener("touchstart", onStartDrag);
      // taskEle.removeEventListener("pointerup", onStopDrag);
      document.body.removeEventListener("pointermove", onTouchMove);
      document.body.removeEventListener("pointerup", onStopDrag);
    };
  }, []);

  return (
    <>
      <div
        onPointerDown={onStartDrag}
        ref={taskRef}
        style={{
          touchAction: !disable && !overrideTouchAction ? "none" : "auto",
        }}
      >
        {!isDragging && children}
        {isDragging && ghostRef && createPortal(children, ghostRef.current)}
      </div>
      {!magnetless && (
        <Magnet
          magnetType={magnetType}
          targetName={targetName}
          ref={magnetRef}
          noSpace={isDragging}
          horizontal={horizontal}
        />
      )}
    </>
  );
}
