import { Howl } from "howler";

export const popSound = new Howl({
  src: ["./pop2.mp3"],
  volume: 0.3,
});

export const releaseSound = new Howl({
  src: ["./release.mp3"],
  volume: 0.3,
});

export const doneSound = new Howl({
  src: ["./done.mp3"],
  volume: 0.6,
});

export const cancelSound = new Howl({
  src: ["./trash.mp3"],
  volume: 0.6,
});

export const trashSound = new Howl({
  src: ["./trash2.mp3"],
  volume: 0.3,
});

export const moveSound = new Howl({
  src: ["./move.mp3"],
  volume: 0.2,
});

export const moveDoneSound = new Howl({
  src: ["./movedone.mp3"],
  volume: 0.2,
});

export const hideSound = new Howl({
  src: ["./hide.mp3"],
  volume: 0.6,
});

export const revealSound = new Howl({
  src: ["./reveal.mp3"],
  volume: 0.9,
});

export const enterSound = new Howl({
  src: ["./enter.mp3"],
  volume: 0.3,
});

export const editEverythingSound = new Howl({
  src: ["./editeverything.mp3"],
  volume: 0.9,
});

export const switchSound = new Howl({
  src: ["./switch.mp3"],
  volume: 0.05,
});

export const antihabitSound = new Howl({ 
  src: ["./antihabit.mp3"],
  volume: 0.9,
});
