import { useCallback, useContext } from "react";

import { AutoEditContext } from "./App";
import { insertHabit } from "./database";

export function NewHabitButton() {
  const { setAutoEdit } = useContext(AutoEditContext);

  const focus = useCallback(() => {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.style.position = "absolute";
    input.style.top = "-100px";
    input.style.width = "0px";
    input.style.height = "0px";
    input.type = "text";
    input.focus();
    setTimeout(() => {
      document.body.removeChild(input);
    }, 100);
  }, []);

  return (
    <div
      className="new-habit-button"
      onClick={(e) => {
        const newHabit = insertHabit();
        setAutoEdit(newHabit);
        e.stopPropagation();
        focus();
      }}
    >
      <div className="new-habit-plus-icon">+</div>
    </div>
  );
}
