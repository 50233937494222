export function Agreement({ setIsAgreed }) {
  return (
    <div className="agreement">
      <h2>
        <strong>againagain</strong> terms of service agreement
      </h2>
      <p>
        againagain is meant to be a small personal habit tracking and management
        tool, not for super-important essential work! here are important things
        to note:
      </p>
      <ul>
        <li>
          all data is stored locally, in the browser. if you clear your browser
          data then your information will be lost. we make no online backups.
        </li>
        <li>
          we aren't responsible for any data-loss or data-corruption with this
          tool. we try our best to be reliable but since it's a one-human team,
          there will be issues.
        </li>
        <li>
          we don't collect any personal information from you, and we don't track
          you. we don't use cookies or any other tracking technology.
        </li>
        <li>
          this is a super-early product and we can't guarantee that it works
          most of the time.
        </li>
        <li>
          we reserve the right to update this agreement from time to time and
          it's up to you to keep yourself up-to-date. the agreement can always
          be accessed at the bottom of the page.
        </li>
        <li>by agreeing, you acknowledge all the above.</li>
      </ul>
      <button className="agreement-button" onClick={setIsAgreed}>
        I Agree
      </button>
    </div>
  );
}
