import { forwardRef, useContext } from "react";

import { TaskDraggerContext } from "./App";

export const Magnet = forwardRef(
  (
    { targetName, noSpace, magnetType, horizontal, block, style, centerSnap },
    ref
  ) => {
    const { initialBoundingRect, globalDragging, ghostTarget } =
      useContext(TaskDraggerContext);

    return (
      <div
        className={[
          `lookup-${magnetType}-magnet`,
          "task-magnet task-magnet-line",
          globalDragging ? "task-magnet-animate" : null,
          globalDragging && targetName === ghostTarget
            ? "task-magnet-hover"
            : null,
          // "task-magnet-horizontal",
          !horizontal ? "task-magnet-horizontal" : "task-magnet-vertical",
          block && "task-magnet-block",
          centerSnap && "task-magnet-center-snap",
        ].join(" ")}
        target={targetName}
        ref={ref}
        style={{
          height: !horizontal
            ? globalDragging && targetName === ghostTarget
              ? initialBoundingRect.height + 10
              : 0
            : "100%",
          width: horizontal
            ? globalDragging && targetName === ghostTarget
              ? initialBoundingRect.width + 10
              : 0
            : "100%",
          marginBottom: globalDragging && noSpace ? -10 : 0,
          marginRight: globalDragging && noSpace ? -36 : 0,
          ...style,
        }}
      ></div>
    );
  }
);
