import {
  AutoEditContext,
  EditingEverythingContext,
  TaskDraggerContext,
} from "./App";
import { TaskType, editTaskName } from "./database";
import { enterSound, moveSound } from "./sounds";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { Draggable } from "./Draggable";
import { Magnet } from "./Magnet";
import { createPortal } from "react-dom";
import { set } from "lodash";
import { useBlockParty } from "./BlockParty";

export function Task({ children, dueDate, taskUuid }) {
  const [task, setTask] = useBlockParty(taskUuid, TaskType, {
    uuid: taskUuid,
    name: "",
    status: "",
  });

  const daysLeftUntilDue =
    dueDate &&
    Math.floor((new Date(dueDate) - new Date()) / (1000 * 60 * 60 * 24));

  const [isEditing, setIsEditing] = useState(false);
  const [taskText, setTaskText] = useState(children);
  const taskWrapperRef = useRef();
  const magnetRef = useRef();

  const { setAutoEdit, autoEdit } = useContext(AutoEditContext);
  const { globalDragging } = useContext(TaskDraggerContext);
  const textAreaRef = useRef();
  const formRef = useRef();

  const focus = useCallback(() => {
    textAreaRef.current.focus();
    textAreaRef.current.click();
  }, []);

  useEffect(() => {
    if (!isEditing) {
      textAreaRef.current.value = task.name;
      setTaskText(task.name);
    }
    textAreaRef.current.style.height = "5px";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  }, [isEditing, task, globalDragging]);

  const targetName = task.uuid + " after";

  useEffect(() => {
    if (autoEdit === taskUuid) {
      setIsEditing(true);
      setAutoEdit(false);
      focus();
    }
  }, [autoEdit, taskUuid, setAutoEdit, focus]);

  useEffect(() => {
    if (isEditing) {
      focus();
    }
  }, [isEditing, focus]);

  const { editingEverything } = useContext(EditingEverythingContext);

  const taskInner = (
    <div
      className="task"
      // ref={taskRef}
      style={{
        pointerEvents: isEditing ? "auto" : "none",
      }}
    >
      {/* {isEditing ? ( */}
      <form
        ref={formRef}
        // style={{ display: isEditing ? "block" : "none" }}
        onSubmit={(e) => {
          setIsEditing(false);
          e.preventDefault();
        }}
      >
        <textarea
          ref={textAreaRef}
          value={taskText}
          onChange={(e) => {
            setTaskText(e.value);
            textAreaRef.current.style.height = "5px";
            textAreaRef.current.style.height =
              textAreaRef.current.scrollHeight + "px";
          }}
          // autoFocus
          onFocus={(e) => {
            e.target.style.height = "5px";
            e.target.style.height = e.target.scrollHeight + "px";
            // Go to the end of the text.
            e.target.selectionStart = e.target.selectionEnd =
              e.target.value.length;
          }}
          onBlur={(e) => {
            e.preventDefault();
            editTaskName(task.uuid, e.target.value);
            setIsEditing(false);
            moveSound.play();
            enterSound.play();
          }}
          onInput={(e) => {
            e.target.style.height = "5px";
            e.target.style.height = e.target.scrollHeight + "px";
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              editTaskName(task.uuid, e.target.value);
              setIsEditing(false);
              e.target.blur();
            }
          }}
        />
      </form>
      {/* ) : ( */}
      {/* {!isEditing && (
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            margin: 0,
            padding: 0,
            fontSize: "inherit",
            fontFamily: "inherit",
          }}
        >
          {task.name || " "}
        </pre> */}
      {/* )} */}
      {/* )} */}
      {dueDate && <div className="task-due">{daysLeftUntilDue} days left.</div>}
    </div>
  );

  return (
    <>
      <Draggable
        onNoDragClick={() => {
          setTaskText(task.name);
          setIsEditing(true);
          focus();
        }}
        disable={isEditing || editingEverything}
        targetName={targetName}
        uuid={task.uuid}
        magnetType="task"
      >
        <div
          className={["task-wrapper", isEditing ? "task-editing" : null].join(
            " "
          )}
          ref={taskWrapperRef}
        >
          {taskInner}
          {/* {!isDragging && taskInner}
          {isDragging && ghostRef && createPortal(taskInner, ghostRef.current)} */}
        </div>
      </Draggable>
    </>
  );
}
