import {
  EditingEverythingContext,
  LoadingContext,
  TaskDraggerContext,
} from "./App";
import { cancelSound, doneSound, moveSound, trashSound } from "./sounds";
import { lookupTask, moveTaskAfter, moveTaskToTop } from "./database";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Magnet } from "./Magnet";
import { NewProjectButton } from "./NewProjectButton";
import { Project } from "./Project";

export function Projects({ projects }) {
  const { editingEverything } = useContext(EditingEverythingContext);
  const { isLoading } = useContext(LoadingContext);

  return (
    <>
      <div className="projects">
        <NewProjectButton
          show={!isLoading && (editingEverything || projects.length === 0)}
        />
        <Magnet magnetType="project" targetName={"root top"} />
        {projects.map((projectUuid) => (
          <Project key={projectUuid} projectUuid={projectUuid} />
        ))}
      </div>
    </>
  );
}
