import { AutoEditContext, EditingEverythingContext } from "./App";
import {
  ProjectType,
  deleteProject,
  fetchProject,
  insertTaskIntoProjectTodo,
  setProjectCollapsed,
  setProjectName,
} from "./database";
import { hideSound, moveSound, revealSound, switchSound } from "./sounds";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { Draggable } from "./Draggable";
import { Magnet } from "./Magnet";
import { Task } from "./Task";
import { useBlockParty } from "./BlockParty";

export function Project({ projectUuid }) {
  const [project, setProject] = useBlockParty(projectUuid, ProjectType, {
    uuid: projectUuid,
    doing: [],
    todo: [],
    waiting: [],
    done: [],
    trash: [],
  });

  useEffect(() => {
    fetchProject(projectUuid);
  }, [projectUuid]);

  // const [showTasks, setShowTasks] = useState(true);
  const [revealedNib, setRevealedNib] = useState("waiting");
  const [editingProjectTitle, setEditingProjectTitle] = useState(false);
  const [projectTitle, setProjectTitle] = useState(project.name || "");

  const { setAutoEdit, autoEdit } = useContext(AutoEditContext);

  const hasTouch = useRef(false);

  const { editingEverything, setEditingEverything } = useContext(
    EditingEverythingContext
  );

  const onToggle = useCallback(
    (e) => {
      if (!project.collapsed) {
        moveSound.play();
        hideSound.play();
      } else {
        moveSound.play();
        revealSound.play();
      }
      setProjectCollapsed(project.uuid, !project.collapsed);
      e.preventDefault();
    },
    [project]
  );

  const projectEditingTimerRef = useRef(null);

  const projectWaitingRef = useRef(null);
  const projectDoneRef = useRef(null);
  const projectTrashRef = useRef(null);
  const changeNib = useCallback((newNib) => {
    switchSound.play();
    setRevealedNib(newNib);
  }, []);

  useEffect(() => {
    if (autoEdit === projectUuid) {
      setEditingProjectTitle(true);
      setAutoEdit(false);
    }
  }, [autoEdit, projectUuid, setAutoEdit]);

  const projectTitleRef = useRef();
  const initialMousePosition = useRef();

  const targetName = projectUuid + " after";

  return (
    <Draggable
      uuid={projectUuid}
      disable={!editingEverything}
      targetName={targetName}
      magnetType="project"
      onNoDragClick={(e) => {
        setEditingEverything(false);
      }}
      overrideTouchAction={true}
    >
      <div
        className="project section"
        style={{
          animationDelay: "-" + Math.random() * 100 + "s",
        }}
      >
        <div
          className="project-header"
          style={{
            touchAction: "none",
          }}
        >
          <h1>
            <span
              className="project-arrow"
              onPointerDown={(e) => {
                onToggle(e);
                e.stopPropagation();
              }}
              style={{
                display: "inline-block",
                padding: 10,
                margin: "-10px 5px -10px -10px",
                transform: !project.collapsed
                  ? "rotate(0deg)"
                  : "rotate(-90deg)",
              }}
            >
              ▼
            </span>
            <div
              className="project-title"
              onPointerMove={(e) => {
                if (projectEditingTimerRef.current) {
                  const dx = e.clientX - initialMousePosition.current.x;
                  const dy = e.clientY - initialMousePosition.current.y;
                  if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
                    clearTimeout(projectEditingTimerRef.current);
                    projectEditingTimerRef.current = null;
                  }
                }
              }}
              onPointerDown={(e) => {
                initialMousePosition.current = { x: e.clientX, y: e.clientY };
                projectEditingTimerRef.current = setTimeout(() => {
                  setEditingEverything(true);
                  document.body.removeEventListener("pointerup", onMouseUp);
                }, 400);
                const onMouseUp = (e) => {
                  clearTimeout(projectEditingTimerRef.current);
                  document.body.removeEventListener("pointerup", onMouseUp);
                };
                document.body.addEventListener("pointerup", onMouseUp);
              }}
              onClick={(e) => {
                if (editingEverything) return;
                setEditingProjectTitle(true);
              }}
            >
              {editingProjectTitle ? (
                <input
                  type="text"
                  ref={projectTitleRef}
                  autoFocus
                  onFocus={(e) => {
                    e.target.value = project.name;
                  }}
                  onBlur={(e) => {
                    setEditingProjectTitle(false);
                    setProjectName(project.uuid, e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setEditingProjectTitle(false);
                      setProjectName(project.uuid, e.target.value);
                    }
                  }}
                />
              ) : (
                <span className="project-title-text">{project.name}</span>
              )}
            </div>
          </h1>
        </div>
        {editingEverything && (
          <>
            <div
              className="project-delete"
              onClick={(e) => {
                deleteProject(projectUuid);
                e.preventDefault();
                e.stopPropagation();
              }}
              onPointerDown={(e) => {
                e.stopPropagation();
              }}
              style={{
                position: "absolute",
                top: 12,
                right: 10,
                width: 30,
                fontSize: 10,
              }}
            >
              <div className="project-tasks-title">❌</div>
            </div>
          </>
        )}
        {!project.collapsed && (
          <div
            className="project-tasks"
            style={{
              touchAction: "auto",
            }}
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="project-tasks-todoing">
              <div className="project-tasks-doing">
                <div className="project-tasks-title">Doing</div>
                <Magnet
                  magnetType="task"
                  targetName={project.uuid + " doing top"}
                />
                {project.doing?.map((taskUuid) => (
                  <Task key={taskUuid} taskUuid={taskUuid} />
                ))}
              </div>
              <div
                className="project-tasks-todo"
                style={{
                  position: "relative",
                }}
              >
                <div className="project-tasks-title">Todo</div>
                <div
                  className="project-tasks-add"
                  onClick={(e) => {
                    if (editingEverything) return;
                    const newUuid = insertTaskIntoProjectTodo(project.uuid, "");
                    setAutoEdit(newUuid);
                  }}
                  style={{
                    position: "absolute",
                    top: 12,
                    right: 10,
                    width: 30,
                    // height: 20,
                    fontSize: 13,
                  }}
                >
                  <div className="project-tasks-title">➕</div>
                </div>
                {/* <div className="project-tasks-scroll-wrapper">
                <div className="project-tasks-scroll"> */}
                <Magnet
                  magnetType="task"
                  targetName={project.uuid + " todo top"}
                />
                {project.todo?.map((taskUuid) => (
                  <Task key={taskUuid} taskUuid={taskUuid} />
                ))}
              </div>
              {/* </div>
              </div> */}
            </div>
            <div className="project-tasks-other">
              <div className="project-tasks-nibs">
                {revealedNib !== "waiting" && (
                  <div
                    className="project-tasks-waiting task-magnet task-magnet-block lookup-task-magnet"
                    target={project.uuid + " waiting top"}
                    onClick={(e) => !editingEverything && changeNib("waiting")}
                  >
                    <div className="project-tasks-title">⏳</div>
                    <div className="project-tasks-badge">
                      {project.waiting?.length}
                    </div>
                  </div>
                )}
                {revealedNib !== "done" && (
                  <div
                    className="project-tasks-done task-magnet task-magnet-block lookup-task-magnet"
                    target={project.uuid + " done top"}
                    onClick={(e) => !editingEverything && changeNib("done")}
                  >
                    <div className="project-tasks-title">✔️</div>
                    <div className="project-tasks-badge">
                      {project.done?.length}
                    </div>
                  </div>
                )}
                {revealedNib !== "trash" && (
                  <div
                    className="project-tasks-trash task-magnet task-magnet-block lookup-task-magnet"
                    target={project.uuid + " trash top"}
                    onClick={(e) => !editingEverything && changeNib("trash")}
                  >
                    <div className="project-tasks-title">🗑️</div>
                  </div>
                )}
              </div>
              {revealedNib === "waiting" && (
                <div className="project-tasks-waiting" ref={projectWaitingRef}>
                  <div className="project-tasks-title">Waiting</div>
                  <Magnet
                    magnetType="task"
                    targetName={project.uuid + " waiting top"}
                  />
                  {project.waiting?.map((taskUuid) => (
                    <Task key={taskUuid} taskUuid={taskUuid} />
                  ))}
                </div>
              )}
              {revealedNib === "done" && (
                <div className="project-tasks-done" ref={projectDoneRef}>
                  <div className="project-tasks-title">Done</div>
                  <Magnet
                    magnetType="task"
                    targetName={project.uuid + " done top"}
                  />
                  {project.done?.map((taskUuid) => (
                    <Task key={taskUuid} taskUuid={taskUuid} />
                  ))}
                </div>
              )}
              {revealedNib === "trash" && (
                <div className="project-tasks-trash" ref={projectTrashRef}>
                  <div className="project-tasks-title">Trash</div>
                  <Magnet
                    magnetType="task"
                    targetName={project.uuid + " trash top"}
                  />
                  {project.trash?.map((taskUuid) => (
                    <Task key={taskUuid} taskUuid={taskUuid} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Draggable>
  );
}
