import { AutoEditContext, EditingEverythingContext } from "./App";
import {
  HabitType,
  fetchHabit,
  isHabitDoneForToday,
  markHabitDoneForToday,
  offsetDay,
  setHabitTitle,
  unmarkHabitDoneForToday,
} from "./database";
import { antihabitSound, popSound, releaseSound } from "./sounds";
import { useContext, useEffect, useRef, useState } from "react";

import { Draggable } from "./Draggable";
import { useBlockParty } from "./BlockParty";

function UUIDToRGB(uuid) {
  // UUID is a string of character and numbers.
  // We need to convert it to a number using charCode.
  const number = uuid
    .split("")
    .map((char) => char.charCodeAt(0))
    .reduce((acc, charCode) => acc + charCode * 10, 0);

  // We need to convert the number to a color.
  const r = ((number >> 16) & 255) * 1;
  const g = ((number >> 8) & 255) * 1;
  const b = (number & 255) * 1;
  return `rgba(${r}, ${g}, ${b}, 0.3)`;
}

function checkLogForDateOffset(log, offset) {
  const today = new Date();
  today.setDate(today.getDate() - offset + offsetDay);
  return log.find((date) => {
    return (
      date.d === today.getDate() &&
      date.m === today.getMonth() &&
      date.y === today.getFullYear()
    );
  });
}

export function Habit({ habitUuid, dragIdx, editEmoji }) {
  useEffect(() => {
    fetchHabit(habitUuid);
  }, [habitUuid]);

  const habitEle = useRef();

  const [habit, setHabit] = useBlockParty(habitUuid, HabitType, {
    uuid: habitUuid,
    name: "",
    icon: "",
    log: [-1],
  });

  const { autoEdit } = useContext(AutoEditContext);
  useEffect(() => {
    if (autoEdit === habitUuid) {
      setEditingHabitTitle(true);
      setEditingEverything(true);
    }
  }, [autoEdit, habitUuid]);

  const hasTouch = useRef(false);
  const [shouldAnimate, setshouldAnimate] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const [editingHabitTitle, setEditingHabitTitle] = useState(false);

  const { editingEverything, setEditingEverything } = useContext(
    EditingEverythingContext
  );

  const projectEditingTimerRef = useRef();

  const today = new Date();
  const possibleToday = habit.log[habit.log.length - 1];
  const habitDone =
    possibleToday &&
    possibleToday.d === today.getDate() + offsetDay &&
    possibleToday.m === today.getMonth() &&
    possibleToday.y === today.getFullYear();

  useEffect(() => {
    if (habitDone) {
      setShowHistory(true);
    }
  }, [habitDone]);

  if (habit.log[0] === -1) {
    return <></>;
  }

  const click = (e) => {
    e.preventDefault();

    if (e.touches) {
      hasTouch.current = true;
    }

    setshouldAnimate(true);

    if (habitDone) {
      releaseSound.play();
      unmarkHabitDoneForToday(habitUuid);
      setShowHistory(false);
    } else {
      if (habit.antihabit) {
        antihabitSound.play();
      } else {
        popSound.play();
      }
      setShowHistory(true);
      markHabitDoneForToday(habitUuid);
      habitEle.current.style.animation = "";
      setTimeout(() => {
        habitEle.current.style.animation = "markHabitDone 0.5s ease-out";
      });
    }
  };

  return (
    <Draggable
      targetName={"habit idx " + dragIdx}
      disable={!editingEverything}
      magnetType="habit"
      horizontal={true}
      magnetless={true}
      uuid={habitUuid}
      onNoDragClick={(e) => {
        // setEditingHabitTitle(true);
        editEmoji();
        // setEditingEverything(false);
      }}
    >
      <div
        className={[
          "habit",
          habit.antihabit ? "antihabit" : null,
          habitDone ? "habit-done" : null,
          shouldAnimate ? "habit-animate" : null,
        ].join(" ")}
        onPointerDown={(e) => {
          // e.stopPropagation();
          projectEditingTimerRef.current = setTimeout(() => {
            setEditingEverything(true);
            document.body.removeEventListener("pointerup", onMouseUp);
          }, 400);
          const onMouseUp = (e) => {
            clearTimeout(projectEditingTimerRef.current);
            document.body.removeEventListener("pointerup", onMouseUp);
          };
          document.body.addEventListener("pointerup", onMouseUp);
        }}
        onClick={(e) => {
          if (editingEverything) {
            e.stopPropagation();
            return;
          }
          click(e);
        }}
        style={{
          animationDelay: "-" + Math.random() * 100 + "s",
        }}
      >
        <div
          className="habit-icon"
          ref={habitEle}
          style={{
            backgroundColor: UUIDToRGB(habitUuid),
          }}
        >
          <div className="habit-icon-inner">{habit.icon}</div>
        </div>
        <div
          className="habit-name"
          onPointerDown={(e) => {
            // if (editingEverything) {
            e.stopPropagation();
            // }
          }}
          onClick={(e) => {
            if (editingEverything) {
              setEditingHabitTitle(true);
            } else {
              setShowHistory(!showHistory);
            }
            e.stopPropagation();
          }}
          style={{
            outline: editingHabitTitle ? "2px solid rgb(140, 140, 240)" : "",
            borderRadius: "5px",
          }}
        >
          {!editingEverything && (
            <div
              className={[
                "habit-progress",
                showHistory ? "habit-progress-visible" : null,
              ].join(" ")}
            >
              {[6, 5, 4, 3, 2, 1].map((offset) => {
                return (
                  <div
                    key={offset}
                    className={[
                      "habit-progress-block",
                      checkLogForDateOffset(habit.log, offset)
                        ? "habit-progress-done"
                        : "habit-progress-skip",
                    ].join(" ")}
                  />
                );
              })}
              <div
                className={[
                  "habit-progress-block",
                  checkLogForDateOffset(habit.log, 0)
                    ? "habit-progress-done-today"
                    : "habit-progress-skip",
                ].join(" ")}
              />
            </div>
          )}
          <div className="habit-name-inner">
            {editingHabitTitle ? (
              <input
                type="text"
                // ref={projectTitleRef}
                autoFocus
                onFocus={(e) => {
                  e.target.value = habit.name;
                }}
                onBlur={(e) => {
                  setEditingHabitTitle(false);
                  setHabitTitle(habit.uuid, e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setEditingHabitTitle(false);
                    setHabitTitle(habit.uuid, e.target.value);
                  }
                }}
              />
            ) : (
              <span
                style={{
                  display: "inline-block",
                  padding: 5,
                  // width: 50,
                }}
              >
                {habit.name}
              </span>
            )}
          </div>
        </div>
      </div>
    </Draggable>
  );
}
