import { LocalBackend } from "./backends/local";
import { createBlockPartyType } from "./BlockParty";
import { v4 as uuidv4 } from "uuid";

export const RootType = createBlockPartyType({});
export const ProjectType = createBlockPartyType({});
export const TaskType = createBlockPartyType({});
export const HabitType = createBlockPartyType({});

const backend = new LocalBackend();

const dbText = localStorage.getItem("db");
let database = (dbText && JSON.parse(dbText)) || {
  root: ["a3jasdr-3o3-asdfz", "2jkasdkf-zsdkf-aasz"],
  projects: [
    {
      uuid: "a3jasdr-3o3-asdfz",
      name: "Learn French",
    },
    {
      uuid: "2jkasdkf-zsdkf-aasz",
      name: "Upload 5 Guitar Videos",
    },
  ],
  tasks: [
    {
      uuid: "jasdfjasdf-asdfzvczz",
      projectUuid: "a3jasdr-3o3-asdfz",
      name: "Register for Duolingo",
      status: "doing",
    },
    {
      uuid: "jasdfjasdf-asdfzvc",
      projectUuid: "a3jasdr-3o3-asdfz",
      name: "Complete 5 Duolingo Lessons",
      status: "todo",
    },
    {
      uuid: "i23041i34mvkzx-kasdf",
      projectUuid: "a3jasdr-3o3-asdfz",
      name: "Apply for French Language School",
      status: "waiting",
    },
    {
      uuid: "i23041i34mvkzx-kasdf2",
      projectUuid: "a3jasdr-3o3-asdfz",
      name: "Apply for French Language School",
      status: "waiting",
    },
    {
      uuid: "i23041i34mvkzx-kasdf3",
      projectUuid: "a3jasdr-3o3-asdfz",
      name: "Apply for French Language School",
      status: "waiting",
    },
    {
      uuid: "amsdfj3-z",
      projectUuid: "a3jasdr-3o3-asdfz",
      name: "Complete 1 Duolingo Lessons",
      status: "done",
    },
  ],
  habits: [
    {
      uuid: "uusdf-jasdfji3-aszzzzdf",
      projectUuid: "root",
      name: "Duolingo",
      icon: "🇫🇷",
    },
    {
      uuid: "uusdfsdfjidf",
      projectUuid: "root",
      name: "Face Routine",
      icon: "🧴",
    },
    {
      uuid: "dsdf-dzfji3-aszzzzzdf",
      projectUuid: "root",
      name: "Run",
      icon: "🏃‍♀️",
    },
    {
      uuid: "uuscdasdf3zzzdf",
      projectUuid: "root",
      name: "Work on Game",
      icon: "👾",
    },
    { uuid: "uzzdf", projectUuid: "root", name: "Read", icon: "📚" },
    {
      uuid: "afkkf-dkdkk3kfk-azzz",
      projectUuid: "root",
      name: "Practice Guitar",
      icon: "🎸",
    },
  ],
  habitLog: [
    {
      habitUuid: "uusdf-jasdfji3-aszzzzdf",
      date: { d: 2, m: 12, y: 2024 },
    },
  ],
};

function saveDb() {
  localStorage.setItem("db", JSON.stringify(database));
}

export async function fetchProjects() {
  const root = await backend.fetchRoot();

  RootType.updateUi("root", root);

  // Wow we have queried projects. Lets go through the tasks we got and update our models.
  // RootType.updateUi("root", {
  //   projects: [...database.root],
  //   habits: database.habits.map((habit) => habit.uuid),
  // });
}

export const offsetDay = 0;

export async function fetchHabit(habitId) {
  // const habit = {
  //   ...database.habits.find((habit) => habit.uuid === habitId),
  //   log: getHabitLog(habitId),
  // };

  const today = new Date();
  const log = (
    await backend.getHabitLogFor7Days(habitId, {
      d: today.getDate() + offsetDay,
      m: today.getMonth(),
      y: today.getFullYear(),
    })
  );

  const habit = {
    ...(await backend.fetchHabit(habitId)),
    log,
  };
  HabitType.updateUi(habit.uuid, habit);
}

export function findHabit(habitId) {
  const habitCached = HabitType.getCachedValue(habitId);
  return habitCached;
}

// export function getTasks(projectId, status) {
//   return database.tasks.filter(
//     (task) => task.projectUuid === projectId && task.status === status
//   );
// }

// export function getTasksCount(projectId, status) {
//   return getTasks(projectId, status).length;
// }

export function lookupTask(taskId) {
  return database.tasks.find((task) => task.uuid === taskId);
}

export function getHabitLog(habitId) {
  return database.habitLog
    .filter((log) => log.habitUuid === habitId)
    .slice(0, 7)
    .map((log) => log.date);
}

export function markHabitDoneForToday(habitId) {
  const today = new Date();
  const newLog = {
    habitUuid: habitId,
    date: {
      d: today.getDate() + offsetDay,
      m: today.getMonth(),
      y: today.getFullYear(),
    },
  };
  backend.markHabitDoneForToday(habitId, newLog.date);
  // if (isHabitDoneForToday(habitId)) {
  //   return;
  // }
  // database.habitLog.push(newLog);
  // saveDb();

  const habitCached = HabitType.getCachedValue(habitId);
  // Check if today is already logged on the client.
  if (
    habitCached.log.find(
      (log) =>
        log.d === today.getDate() + offsetDay &&
        log.m === today.getMonth() &&
        log.y === today.getFullYear()
    )
  ) {
    return;
  }
  HabitType.updateUi(habitId, {
    ...habitCached,
    log: [...habitCached.log, newLog.date],
  });
}

export function unmarkHabitDoneForToday(habitId) {
  const today = new Date();
  backend.unmarkHabitDoneForToday(habitId, {
    d: today.getDate() + offsetDay,
    m: today.getMonth(),
    y: today.getFullYear(),
  });
  // const logIndex = database.habitLog.findIndex((log) => {
  //   return (
  //     log.habitUuid === habitId &&
  //     log.date.d === (today.getDate() + offsetDay) &&
  //     log.date.m === today.getMonth() &&
  //     log.date.y === today.getFullYear()
  //   );
  // });
  // if (logIndex === -1) {
  //   return;
  // }
  // database.habitLog.splice(logIndex, 1);
  // saveDb();

  const habitCached = HabitType.getCachedValue(habitId);
  HabitType.updateUi(habitId, {
    ...habitCached,
    log: habitCached.log.filter((log) => {
      return !(
        log.d === today.getDate() + offsetDay &&
        log.m === today.getMonth() &&
        log.y === today.getFullYear()
      );
    }),
  });
}

export function isHabitDoneForToday(habitId) {
  const today = new Date();
  return database.habitLog.find((log) => {
    return (
      log.habitUuid === habitId &&
      log.date.d === today.getDate() + offsetDay &&
      log.date.m === today.getMonth() &&
      log.date.y === today.getFullYear()
    );
  });
}

export function moveTaskAfter(srcTaskId, afterTaskId) {
  if (srcTaskId === afterTaskId) {
    return false;
  }

  // Update the client. Lowkey this is kinda gross...
  const cachedSrcTask = TaskType.getCachedValue(srcTaskId);
  const cachedAfterTask = TaskType.getCachedValue(afterTaskId);
  const cachedSrcProject = ProjectType.getCachedValue(
    cachedSrcTask.projectUuid
  );
  let cachedAfterProject = ProjectType.getCachedValue(
    cachedAfterTask.projectUuid
  );
  if (cachedSrcTask.projectUuid === cachedAfterTask.projectUuid) {
    cachedAfterProject = cachedSrcProject;
  }
  if (
    cachedSrcTask &&
    cachedAfterTask &&
    cachedSrcProject &&
    cachedAfterProject
  ) {
    const srcTaskStatus = cachedSrcTask.status;
    const afterTaskStatus = cachedAfterTask.status;
    const srcTaskIndexInProject =
      cachedSrcProject[srcTaskStatus].indexOf(srcTaskId);
    let afterTaskIndexInProject =
      cachedAfterProject[afterTaskStatus].indexOf(afterTaskId);
    if (
      srcTaskIndexInProject === afterTaskIndexInProject + 1 &&
      srcTaskStatus === afterTaskStatus &&
      cachedSrcTask.projectUuid === cachedAfterTask.projectUuid
    ) {
      return false;
    }
    cachedSrcProject[srcTaskStatus].splice(srcTaskIndexInProject, 1);
    afterTaskIndexInProject =
      cachedAfterProject[afterTaskStatus].indexOf(afterTaskId);
    cachedAfterProject[afterTaskStatus].splice(
      afterTaskIndexInProject + 1,
      0,
      srcTaskId
    );
    cachedSrcTask.projectUuid = cachedAfterTask.projectUuid;
    cachedSrcTask.status = afterTaskStatus;
    TaskType.updateUi(srcTaskId, cachedSrcTask);
    ProjectType.updateUi(cachedSrcProject.uuid, cachedSrcProject);
    ProjectType.updateUi(cachedAfterProject.uuid, cachedAfterProject);
  }

  // Update the server.
  // const srcTask = lookupTask(srcTaskId);
  // const afterTask = lookupTask(afterTaskId);
  // srcTask.projectUuid = afterTask.projectUuid;
  // srcTask.status = afterTask.status;
  // const srcTaskIndex = database.tasks.indexOf(srcTask);
  // database.tasks.splice(srcTaskIndex, 1);
  // const afterTaskIndex = database.tasks.indexOf(afterTask);
  // database.tasks.splice(afterTaskIndex + 1, 0, srcTask);
  // saveDb();
  backend.moveTaskAfter(srcTaskId, afterTaskId);

  return true;
}

export function moveTaskToTop(srcTaskId, projectUuid, status) {
  // Update the client.
  const cachedProject = ProjectType.getCachedValue(projectUuid);
  const cachedTask = TaskType.getCachedValue(srcTaskId);
  const cachedSrcProject = ProjectType.getCachedValue(cachedTask.projectUuid);
  if (cachedProject && cachedTask) {
    let srcTaskIndex = cachedSrcProject[cachedTask.status].indexOf(srcTaskId);
    if (
      srcTaskIndex === 0 &&
      cachedTask.projectUuid === projectUuid &&
      cachedTask.status === status
    ) {
      return false;
    }
    console.log(srcTaskId);
    console.log(srcTaskIndex);
    console.log(cachedSrcProject[cachedTask.status]);
    cachedSrcProject[cachedTask.status].splice(srcTaskIndex, 1);
    cachedProject[status].splice(0, 0, srcTaskId);

    cachedTask.projectUuid = projectUuid;
    cachedTask.status = status;
    TaskType.updateUi(srcTaskId, { ...cachedTask });
    ProjectType.updateUi(cachedSrcProject.projectUuid, { ...cachedSrcProject });
    ProjectType.updateUi(projectUuid, { ...cachedProject });
  }

  // Send to server
  backend.moveTaskToTop(srcTaskId, projectUuid, status);
  // const srcTask = lookupTask(srcTaskId);
  // srcTask.projectUuid = projectUuid;
  // srcTask.status = status;
  // const srcTaskIndex = database.tasks.indexOf(srcTask);
  // database.tasks.splice(srcTaskIndex, 1);
  // database.tasks.splice(0, 0, srcTask);
  // saveDb();

  return true;
}

export function editTaskName(taskId, newName) {
  // Update the client.
  const taskCached = TaskType.getCachedValue(taskId);
  taskCached.name = newName;
  TaskType.updateUi(taskId, taskCached);

  // Send to server.
  // const task = lookupTask(taskId);
  // task.name = newName;
  // saveDb();
  backend.setTaskName(taskId, newName);
}

export function insertTaskIntoProjectTodo(projectId, taskName) {
  const newTask = {
    uuid: uuidv4(),
    projectUuid: projectId,
    name: taskName,
    status: "todo",
  };
  backend.createTask(projectId, newTask);

  // Update the project UI as well.
  TaskType.updateUi(newTask.uuid, newTask);
  const cachedProject = ProjectType.getCachedValue(projectId);
  if (cachedProject) {
    cachedProject.todo.unshift(newTask.uuid);
    ProjectType.updateUi(projectId, { ...cachedProject });
  }

  // Send to server.
  database.tasks.splice(0, 0, newTask);
  saveDb();

  return newTask.uuid;
}

export function setProjectName(projectId, newName) {
  // Update the client.
  const projectCached = { ...ProjectType.getCachedValue(projectId) };
  projectCached.name = newName;
  ProjectType.updateUi(projectId, projectCached);

  // Send to server.
  backend.setProjectName(projectId, newName);
  // const project = database.projects.find(
  //   (project) => project.uuid === projectId
  // );
  // project.name = newName;
  // saveDb();
}

export function setProjectCollapsed(projectId, collapsed) {
  // Update the client.
  const projectCached = { ...ProjectType.getCachedValue(projectId) };
  projectCached.collapsed = collapsed;
  ProjectType.updateUi(projectId, projectCached);

  // Send to server.
  backend.setProjectCollapsed(projectId, collapsed);
  // const project = database.projects.find(
  //   (project) => project.uuid === projectId
  // );
  // project.collapsed = collapsed;
  // saveDb();
}

export async function fetchTasks(projectId, status) {
  const tasks = (await backend.fetchTasks(projectId, status)).map((task) => {
    return { ...task };
  });
  tasks.forEach((task) => {
    TaskType.updateUi(task.uuid, task);
  });
  return tasks;
}

export async function fetchProject(projectId) {
  // const project = database.projects.find(
  //   (project) => project.uuid === projectId
  // );
  // if (!project) {
  //   return;
  // }
  const project = { ...(await backend.fetchProject(projectId)) };
  project.doing = (await fetchTasks(projectId, "doing")).map(
    (task) => task.uuid
  );
  project.todo = (await fetchTasks(projectId, "todo")).map((task) => task.uuid);
  project.waiting = (await fetchTasks(projectId, "waiting")).map(
    (task) => task.uuid
  );
  project.done = (await fetchTasks(projectId, "done")).map((task) => task.uuid);
  project.trash = (await fetchTasks(projectId, "trash")).map(
    (task) => task.uuid
  );
  ProjectType.updateUi(projectId, project);
}

export function insertNewProject() {
  const newProject = {
    uuid: uuidv4(),
    name: "",
    habits: [],
  };

  // Send to server.
  // database.projects.splice(0, 0, newProject);
  // database.root.splice(0, 0, newProject.uuid);
  // saveDb();
  backend.createProject(newProject);

  fetchProject(newProject.uuid);

  // Update the project UI as well.
  ProjectType.updateUi(newProject.uuid, newProject);
  RootType.updateUi("root", {
    ...RootType.getCachedValue("root"),
    projects: [newProject.uuid, ...RootType.getCachedValue("root").projects],
  });

  return newProject.uuid;
}

export function deleteProject(projectUuid) {
  backend.deleteProject(projectUuid);
  // const projectIndex = database.projects.findIndex(
  //   (project) => project.uuid === projectUuid
  // );
  // if (projectIndex === -1) {
  //   return;
  // }
  // database.root.splice(database.root.indexOf(projectUuid), 1);
  // database.projects.splice(projectIndex, 1);
  // saveDb();

  RootType.updateUi("root", {
    ...RootType.getCachedValue("root"),
    projects: RootType.getCachedValue("root").projects.filter(
      (uuid) => uuid !== projectUuid
    ),
  });
}

export function moveProjectToTop(projectUuid) {
  // const projectIndex = database.root.indexOf(projectUuid);
  // database.root.splice(projectIndex, 1);
  // database.root.splice(0, 0, projectUuid);
  // saveDb();
  backend.moveProjectToTop(projectUuid);

  RootType.updateUi("root", {
    ...RootType.getCachedValue("root"),
    projects: [
      projectUuid,
      ...RootType.getCachedValue("root").projects.filter(
        (uuid) => uuid !== projectUuid
      ),
    ],
  });
}

export function moveProjectAfter(srcProjectUuid, afterProjectUuid) {
  if (srcProjectUuid === afterProjectUuid) {
    return;
  }

  backend.moveProjectAfter(srcProjectUuid, afterProjectUuid);
  // const srcProjectIndex = database.root.indexOf(srcProjectUuid);
  // database.root.splice(srcProjectIndex, 1);
  // const afterProjectIndex = database.root.indexOf(afterProjectUuid);
  // database.root.splice(afterProjectIndex + 1, 0, srcProjectUuid);
  // saveDb();

  const projects = RootType.getCachedValue("root").projects.filter(
    (uuid) => uuid !== srcProjectUuid
  );
  const afterProjectIndexInRoot = projects.indexOf(afterProjectUuid);
  projects.splice(afterProjectIndexInRoot + 1, 0, srcProjectUuid);
  RootType.updateUi("root", { ...RootType.getCachedValue("root"), projects });
}

export function moveHabitToIndex(habitUuid, index) {
  // const habitIndex = database.habits.findIndex(
  //   (habit) => habit.uuid === habitUuid
  // );
  // if (habitIndex === -1) {
  //   return;
  // }
  // if (habitIndex === index) {
  //   return;
  // }
  // const habit = database.habits[habitIndex];
  // database.habits.splice(habitIndex, 1);
  // database.habits.splice(index, 0, habit);
  // saveDb();
  backend.moveHabitToIndex(habitUuid, index);

  const habitsCached = RootType.getCachedValue("root").habits;
  const habitIndexInRoot = habitsCached.indexOf(habitUuid);
  habitsCached.splice(habitIndexInRoot, 1);
  habitsCached.splice(index, 0, habitUuid);
  RootType.updateUi("root", {
    ...RootType.getCachedValue("root"),
    habits: habitsCached,
  });
}

export function deleteHabit(habitUuid) {
  // const habitIndex = database.habits.findIndex(
  //   (habit) => habit.uuid === habitUuid
  // );
  // database.habits.splice(habitIndex, 1);
  // saveDb();
  backend.deleteHabit(habitUuid);

  RootType.updateUi("root", {
    ...RootType.getCachedValue("root"),
    habits: RootType.getCachedValue("root").habits.filter(
      (uuid) => uuid !== habitUuid
    ),
  });
}

export function setHabitEmoji(habitUuid, emoji) {
  // const habit = database.habits.find((habit) => habit.uuid === habitUuid);
  // habit.icon = emoji;
  // saveDb();
  backend.setHabitIcon(habitUuid, emoji);

  const habitCached = HabitType.getCachedValue(habitUuid);
  habitCached.icon = emoji;
  HabitType.updateUi(habitUuid, { ...habitCached });
}

export function setHabitTitle(habitUuid, title) {
  // const habit = database.habits.find((habit) => habit.uuid === habitUuid);
  // habit.name = title;
  // saveDb();
  backend.setHabitName(habitUuid, title);

  const habitCached = HabitType.getCachedValue(habitUuid);
  habitCached.name = title;
  HabitType.updateUi(habitUuid, { ...habitCached });
}

export function setHabitAnti(habitUuid, antihabit) {
  backend.setHabitAnti(habitUuid, antihabit);

  const habitCached = HabitType.getCachedValue(habitUuid);
  habitCached.antihabit = antihabit;
  HabitType.updateUi(habitUuid, { ...habitCached });
}

export function insertHabit() {
  const newHabit = {
    uuid: uuidv4(),
    projectUuid: "root",
    name: "",
    icon: "",
  };
  backend.createHabit(newHabit);
  // database.habits.push(newHabit);
  // saveDb();

  RootType.updateUi("root", {
    ...RootType.getCachedValue("root"),
    habits: [...RootType.getCachedValue("root").habits, newHabit.uuid],
  });
  HabitType.updateUi(newHabit.uuid, { ...newHabit, log: [] });
  return newHabit.uuid;
}
