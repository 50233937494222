// UUID-based data syncing for React. We do not want to do any real fetching here.
// Fetchers should update the data here themselves.

import { useCallback, useEffect, useState } from "react";

export function createBlockPartyType({ defaultValue, retrieve, update }) {
  const eventTarget = new EventTarget();
  const cache = new Map();
  const updateUi = (uuid, newData) => {
    cache.set(uuid, newData);
    eventTarget.dispatchEvent(
      new CustomEvent(uuid, { detail: { uuid, newData } })
    );
  };
  const getCachedValue = (uuid) => cache.get(uuid);
  return {
    defaultValue,
    addEventListener: eventTarget.addEventListener.bind(eventTarget),
    removeEventListener: eventTarget.removeEventListener.bind(eventTarget),
    retrieve,
    updateToServer: update,
    updateUi: updateUi,
    getCachedValue,
  };
}

export function useBlockParty(uuid, BlockPartyType, defaultValue) {
  const [data, setData] = useState(
    BlockPartyType.getCachedValue(uuid) ||
      defaultValue ||
      BlockPartyType.defaultValue
  );

  // Update the data and sync with the server.
  const setDataAndSync = useCallback(
    (newData) => {
      if (newData instanceof Function) {
        newData = newData(data);
      }
      setData(newData);
      BlockPartyType.updateUi(uuid, newData);
    },
    [uuid, BlockPartyType, data]
  );

  // Sync with the global state of this block party type.
  useEffect(() => {
    const eventListener = BlockPartyType.addEventListener(uuid, (data) => {
      setData(data.detail.newData);
    });
    return () => {
      BlockPartyType.removeEventListener(uuid, eventListener);
    };
  }, [BlockPartyType, uuid]);

  return [data, setDataAndSync];
}
