import { BaseBackend } from "./base";
import Dexie from "dexie";
import { Lexorank } from "./lexorank";

const lexorank = new Lexorank();

export class LocalBackend extends BaseBackend {
  constructor() {
    super();

    this.db = new Dexie("againagain");
    this.db.version(4).stores({
      habits: "uuid, projectUuid, name, icon, antihabit",
      habitLogs: "[habitUuid+date]",
      tasks: "uuid, [projectUuid+status], name, rank",
      projects: "uuid, name",
      roots: "uuid, projects, habits",
    });
  }

  async fetchRoot() {
    const root = await this.db.roots.get("root");
    if ((await this.db.roots.get("root")) === undefined) {
      const newRoot = {
        uuid: "root",
        projects: [],
        habits: [],
      };
      await this.db.roots.put(newRoot);
      return newRoot;
    }
    return root;
  }

  async fetchHabit(habitUuid) {
    return await this.db.habits.get(habitUuid);
  }
  async fetchProject(projectUuid) {
    return await this.db.projects.get(projectUuid);
  }
  async fetchTasks(projectUuid, status) {
    return await this.db.tasks.where({ projectUuid, status }).sortBy("rank");
  }

  async createHabit(habit) {
    await this.db.habits.put(habit);

    const root = await this.fetchRoot();
    root.habits.push(habit.uuid);
    await this.db.roots.put(root);
  }
  async deleteHabit(habitUuid) {
    await this.db.habits.delete(habitUuid);

    const root = await this.fetchRoot();
    root.habits = root.habits.filter((uuid) => uuid !== habitUuid);
    await this.db.roots.put(root);
  }
  async setHabitName(habitUuid, name) {
    await this.db.habits.update(habitUuid, { name });
  }
  async setHabitIcon(habitUuid, icon) {
    await this.db.habits.update(habitUuid, { icon });
  }
  async setHabitAnti(habitUuid, antihabit) {
    await this.db.habits.update(habitUuid, { antihabit });
  }
  async getHabitLogFor7Days(habitUuid, today) {
    const integerToday = this.integerifyDate(today);
    return (
      (
        await this.db.habitLogs
          .where("[habitUuid+date]")
          .between(
            [habitUuid, integerToday - 8],
            [habitUuid, integerToday],
            true,
            true
          )
          .sortBy("date")
      )
        // .reverse()
        .map((habitLog) => this.dateifyInteger(habitLog.date))
    );
  }
  integerifyDate(date) {
    return date.y * 10000 + date.m * 100 + date.d;
  }
  dateifyInteger(integer) {
    return {
      y: Math.floor(integer / 10000),
      m: Math.floor((integer % 10000) / 100),
      d: integer % 100,
    };
  }
  async markHabitDoneForToday(habitUuid, date) {
    date = this.integerifyDate(date);
    if ((await this.db.habitLogs.where({ habitUuid, date }).count()) === 0) {
      await this.db.habitLogs.put({
        habitUuid,
        date,
      });
    }
  }
  async unmarkHabitDoneForToday(habitUuid, date) {
    date = this.integerifyDate(date);
    await this.db.habitLogs.where({ habitUuid, date }).delete();
  }
  async moveHabitToIndex(habitUuid, index) {
    const root = await this.fetchRoot();
    const habitIndex = root.habits.indexOf(habitUuid);
    if (habitIndex === -1 || index === -1 || habitIndex === index) {
      return;
    }
    root.habits.splice(habitIndex, 1);
    root.habits.splice(index, 0, habitUuid);
    await this.db.roots.put(root);
  }

  async createProject(project) {
    await this.db.projects.put(project);

    const root = await this.fetchRoot();
    root.projects.push(project.uuid);
    await this.db.roots.put(root);
  }
  async deleteProject(projectUuid) {
    const root = await this.fetchRoot();
    root.projects = root.projects.filter((uuid) => uuid !== projectUuid);
    await this.db.roots.put(root);

    try {
      await this.db.projects.delete(projectUuid);
    } catch (e) {
      console.log(e);
    }
  }
  async moveProjectToTop(projectUuid) {
    const root = await this.fetchRoot();
    const projectIndex = root.projects.indexOf(projectUuid);
    if (projectIndex === -1) {
      return;
    }
    console.log(root.projects);
    root.projects.splice(projectIndex, 1);
    root.projects.unshift(projectUuid);
    console.log(root.projects);
    console.log(root);
    await this.db.roots.put(root);
  }
  async moveProjectAfter(projectUuid, afterProjectUuid) {
    const root = await this.fetchRoot();
    const projectIndex = root.projects.indexOf(projectUuid);
    const afterProjectIndex = root.projects.indexOf(afterProjectUuid);
    if (projectIndex === -1 || afterProjectIndex === -1) {
      return;
    }
    console.log(root.projects);
    root.projects.splice(projectIndex, 1);
    root.projects.splice(afterProjectIndex + 1, 0, projectUuid);
    console.log(root.projects);
    console.log(root);
    await this.db.roots.put(root);
  }
  async setProjectName(projectUuid, name) {
    await this.db.projects.update(projectUuid, { name });
  }
  async setProjectCollapsed(projectUuid, collapsed) {
    await this.db.projects.update(projectUuid, { collapsed });
  }

  async createTask(projectUuid, task) {
    const taskTop = (
      await this.db.tasks
        .where({ projectUuid: projectUuid, status: "todo" })
        .sortBy("rank")
    )[0];
    task.rank = lexorank.insert(null, taskTop?.rank)[0];
    await this.db.tasks.put(task);
  }
  async deleteTask(taskUuid) {
    await this.db.tasks.delete(taskUuid);
  }
  async setTaskName(taskUuid, name) {
    await this.db.tasks.update(taskUuid, { name });
  }
  async moveTaskToTop(taskUuid, projectUuid, status) {
    const task = await this.db.tasks.get(taskUuid);
    const taskTop = (
      await this.db.tasks.where({ projectUuid, status: status }).sortBy("rank")
    )[0];
    task.status = status;
    task.rank = lexorank.insert(null, taskTop?.rank)[0];
    await this.db.tasks.update(taskUuid, task);
  }
  async moveTaskAfter(taskUuid, afterTaskUuid) {
    const task = await this.db.tasks.get(taskUuid);
    const afterTask = await this.db.tasks.get(afterTaskUuid);
    const allTasks = (
      await this.db.tasks
        .where({ projectUuid: task.projectUuid, status: task.status })
        .sortBy("rank")
    ).filter((t) => t.uuid !== taskUuid);
    const nextTask = afterTask.uuid
      ? allTasks[allTasks.findIndex((t) => t.uuid === afterTask.uuid) + 1]
      : null;
    task.status = afterTask.status;
    task.rank = lexorank.insert(afterTask.rank, nextTask?.rank)[0];
    await this.db.tasks.update(taskUuid, task);
  }
}
